import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import "./_index.scss";

const Notice = ({ open, text, className, state }) => {
  return (
    <Box sx={{ width: "100%" }} className={className}>
      <Collapse in={open}>
        <Alert sx={{ mb: 2 }} severity={state} className={`${state}-alert`}>
          {text}
        </Alert>
      </Collapse>
    </Box>
  );
};
export default Notice;
